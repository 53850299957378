import React from 'react';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-kanban';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FiPlus } from 'react-icons/fi';

import { kanbanGrid } from '../data/dummy';
import { Header } from '../components';

const Aufgaben = ({ kanbanData, currentColor }) => (
  <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    <Header category="App" title="Aufgaben" />
    <KanbanComponent
      id="kanban"
      keyField="Status"
      dataSource={kanbanData}
      enablePersistence
      cardSettings={{ contentField: 'Beschreibung', headerField: 'Id' }}
    >
      <ColumnsDirective>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {kanbanGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
      </ColumnsDirective>
    </KanbanComponent>
    <div className="flex flex-row-reverse" style={{ zIndex: '1000' }}>
      <TooltipComponent
        content="Karte hinzufügen"
        position="Top"
      >
        <button
          type="button"
          style={{ background: currentColor, borderRadius: '50%' }}
          className="mt-3 mr-3 text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
          /* onClick={() => {
            const cardDetails = {
              Id: 'Task ' + kanbanData.length,
              Titel: 'Task - 29001',
              Status: 'Chancen',
              Beschreibung: 'Analyze the new requirements gathered from the customer.',
              Typ: 'Story',
            };
          }} */
        >
          <FiPlus />
        </button>

      </TooltipComponent>
    </div>
  </div>
);

export default Aufgaben;
