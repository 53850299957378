import React from 'react';

import Userfront from '@userfront/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useStateContext } from '../../contexts/ContextProvider';

import myIconWhite from '../../assets/RI_Logo_hellblau_RGB.png';
import myIconBlack from '../../assets/RI_Logo_schwarz_RGB.png';

const { useState } = React;

Userfront.init('xbrrz64b');

const Login = () => {
  const { currentColor, currentMode } = useStateContext();

  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showPw, setShowPw] = useState(false);
  const [showPwType, setShowPwType] = useState('password');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleErrorMsg = (e) => setErrorMsg(e);
  const handleIsSubmitting = (e) => setIsSubmitting(e);
  const handleShowPw = (e) => setShowPw(e);
  const handleShowPwType = (e) => setShowPwType(e);

  function togglePWVisibility() {
    handleShowPw(!showPw);
    if (showPwType === 'password') {
      handleShowPwType('text');
    } else {
      handleShowPwType('password');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    handleIsSubmitting(true);
    Userfront.login({
      method: 'password',
      emailOrUsername: userName,
      password,
    }).catch(() => {
      // console.log(error);
      handleErrorMsg('Fehler beim Anmelden. Bitte erneut versuchen!');
      handleIsSubmitting(false);
    });
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center bg-white dark:bg-[#42464D] pt-8 px-8 rounded-lg w-400">
        <img
          className="w-48 h-14"
          src={currentMode === 'Light' ? myIconBlack : myIconWhite}
          alt="Logo"
        />
        <form className="pt-6 pb-8 mb-4 w-full" onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="block text-gray-500 text-sm font-bold dark:text-gray-400 mb-2" htmlFor="username">
              Benutzername oder Email
            </div>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Name" value={userName} onChange={handleUsernameChange} />
          </div>
          <div className="mb-6">
            <div className="block text-gray-500 text-sm font-bold dark:text-gray-400 mb-2" htmlFor="password">
              Password
            </div>
            <div className="relative w-full">
              <div className="absolute inset-y-4 right-0 flex items-center px-2">
                <div
                  className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label"
                  htmlFor="toggle"
                  onClick={togglePWVisibility}
                >
                  {showPw ? <MdVisibilityOff /> : <MdVisibility />}
                </div>
              </div>
            </div>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type={showPwType} placeholder="***********" value={password} onChange={handlePasswordChange} />
            <p className="text-red-500 text-xs italic">{errorMsg}</p>
          </div>
          <div className="flex items-center justify-between">
            <button
              type="submit"
              style={{ backgroundColor: currentColor }}
              className="flex items-center font-bold text-sm text-white hover:drop-shadow-xl rounded-lg p-4"
            >
              Anmelden
              {isSubmitting && (
              <svg className="animate-spin h-5 w-5 ml-3" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              )}
            </button>
            <a className="inline-block align-baseline font-bold text-sm hover:underline" style={{ color: currentColor }} href="/reset">
              Password vergessen?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
