import React from 'react';
import { Header } from '../components';

const Dashboard = () => (
  <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    <Header category="Apps" title="Dashboard" />
  </div>
);

export default Dashboard;
