import React, { useEffect } from 'react';

import { Header } from '../components';

const Terminvereinbarung = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '25670964',
          formId: '6dae1fe6-962a-466a-a181-5eb4ee3f80ec',
          target: '#hubspotForm',
        });
      }
    });

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Apps" title="VIP Newsletter" />
      <div id="hubspotForm" />
    </div>
  );
};
export default Terminvereinbarung;
