import React, { useEffect } from 'react';

import { Header } from '../components';

const Terminvereinbarung = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Apps" title="Terminvereinbarung" />
      <div>
        <div className="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/philipp-bundschu/beratungstermin-via-plattform-?embed=true" />
      </div>
    </div>
  );
};
export default Terminvereinbarung;
