import React from 'react';
import Userfront from '@userfront/react';

Userfront.init('xbrrz64b');

const PasswordResetForm = Userfront.build({
  toolId: 'lrkrdo',
});

const Reset = () => (
  <PasswordResetForm />
);

export default Reset;
