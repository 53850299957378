import React, { useEffect } from 'react';

import { Header } from '../components';

const Tour = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://developer.ogulo.com/js/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl h-screen">
      <Header category="Apps" title="360° Tour" />
      <div className="h-5/6">
        <ogulo-tour tour="0Sow" />
      </div>
    </div>
  );
};
export default Tour;
