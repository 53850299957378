import React from 'react';
import Userfront from '@userfront/react';

Userfront.init('xbrrz64b');

const SignupForm = Userfront.build({
  toolId: 'rdodmr',
});

const SignUp = () => (
  <SignupForm />
);

export default SignUp;
